export const redirectToEvents = () => {
    window.location = '/';
};

export const redirectToDashboard = () => {
    window.location = '/de/admin';
};

export const redirectToLogout = () => {
    window.location = '/app/logout';
};

export const redirectToBackend = () => {
    window.location = '/';
};

export const redirectToProfile = (accountId) => {
    window.location =
        '/de/admin?crudAction=detail&crudControllerFqcn=App%5CController%5CAdmin%5CAccountCrudController&entityId=' +
        accountId;
};
