import React, { useEffect, useState } from 'react';
import '../../Layout/_reset.scss';
import Chat from '../../Chat/Chat';
import QuestionSection from '../../Questions/QuestionSection';
import css from './Editor.module.scss';
import mercure from '../../utils/mercure';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { getEventById, handleResponseErrors } from '../../utils/apiClient';
import { Trans, useTranslation } from 'react-i18next';
import { redirectToDashboard, redirectToProfile } from '../../utils/redirects';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AccountCircle, Logout, Place } from '@mui/icons-material';
import FlagIcon from '@mui/icons-material/Flag';
import Navbar from '../../Block/Navbar';
import { assertNotNull, assertUuid4 } from '../../utils/assert';
import { useAtom, useSetAtom } from 'jotai';
import { eventAtom, settingsDialog } from '../../Atom/UserAtom';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsDialog from '../../Dialog/SettingsDialog';
import { useLogout } from '../../utils/Login';
import useLogin from '../../utils/useLogin';
import WhiteButton from '../../Form/WhiteButton';
import { classNames } from '../../utils/classNames';
import LoginPage from '../../Login/LoginPage';

function Editor() {
    const [event, setEvent] = useAtom(eventAtom);
    const [fontSize, setFontSize] = useState(16);
    const [messageEvent, setMessageEvent] = useState(null);
    const [openDrawer, setOpenDrawerDrawer] = useState(false);
    const { eventId } = useParams();
    const setSettingsOpen = useSetAtom(settingsDialog);
    const handleLogout = useLogout();
    const { t, i18n } = useTranslation();
    const [userData] = useLogin();

    const currentLanguage = () =>
        i18n.language || window.localStorage.i18nextLng;
    const drawerMinWidth = 20;

    const handleLanguageChange = () => {
        if (currentLanguage() === 'en') {
            i18n.changeLanguage('de');
        } else {
            i18n.changeLanguage('en');
        }
    };

    const menuItems = [
        {
            name: 'profile',
            callback: () => redirectToProfile(userData?.id),
            icon: <AccountCircle fontSize="small" />,
        },
        {
            name: 'event',
            callback: () => redirectToDashboard(),
            icon: <Place fontSize="small" />,
        },
        { divider: true },
        {
            name: 'language',
            callback: handleLanguageChange,
            icon: <FlagIcon fontSize="small" />,
        },
        {
            name: 'settings',
            callback: () => setSettingsOpen(true),
            icon: <SettingsIcon fontSize="small" />,
        },
        { divider: true },
        {
            name: 'logout',
            callback: handleLogout,
            icon: <Logout fontSize="small" />,
        },
        { divider: true },
    ];

    useEffect(() => {
        try {
            assertNotNull(eventId);
            assertUuid4(eventId);
        } catch (e) {
            window.location = '/de/admin/';
            return;
        }

        let mercureEvent = null;

        if (!userData) {
            return;
        }

        getEventById(eventId)
            .then((response) => {
                setEvent(response.data);
                mercureEvent = mercure(
                    ['/api/events/' + eventId],
                    messageEvent?.lastEventId ?? null,
                );

                mercureEvent.onmessage = (event) => {
                    if (event?.data === null) {
                        return;
                    }
                    let data = JSON.parse(event.data);

                    if (data.published === true) {
                        enqueueSnackbar(t('mercure.event_published'), {
                            variant: 'success',
                            preventDuplicate: true,
                            key: 'event_published',
                        });
                    }

                    if (data.published !== true) {
                        enqueueSnackbar(t('mercure.event_unpublished'), {
                            variant: 'warning',
                            preventDuplicate: true,
                            key: 'event_unpublished',
                        });
                    }

                    setEvent(data);
                    setMessageEvent(event);
                };

                mercureEvent.onerror = () => {
                    enqueueSnackbar(
                        'Fehler beim Laden des Event, bitte die Seite aktualisieren.',
                        { variant: 'error' },
                    );
                };
            })
            .catch((response) =>
                handleResponseErrors(response, () => {
                    enqueueSnackbar(t('error.event_not_found'), {
                        variant: 'error',
                        action: () => (
                            <WhiteButton
                                text="error.event_not_found_btn"
                                action={redirectToDashboard}
                            />
                        ),
                    });
                }),
            );

        return () => {
            try {
                mercureEvent?.close();
            } catch (e) {
                // intentional empty
            }
        };
    }, [eventId, userData]);

    if (!userData) {
        return <LoginPage />;
    }

    const toggleDrawer = (newOpen) => () => {
        setOpenDrawerDrawer(newOpen);
    };

    let style = {
        fontSize: fontSize + 'px',
    };

    const btnStyle =
        openDrawer === false
            ? {
                  minWidth: drawerMinWidth,
                  justifyContent: 'center',
              }
            : {};
    const drawerStyle = openDrawer ? { width: '280px' } : { width: '60px' };

    let published = event?.published;
    const classes = {};
    classes[css.header] = true;
    classes[css.unpublished] = eventId && published !== true;

    let title = event?.name;
    if (false === published) {
        title = (
            <Trans
                i18nKey="navbar.unpublished_postfix"
                values={{ name: event?.name }}
            />
        );
    }

    return (
        <Box className={css.container} sx={style}>
            <Box>
                <Box component="header" className={classNames(classes)}>
                    <Navbar
                        title={title}
                        increaseFontSize={() => setFontSize(fontSize + 2)}
                        decreaseFontSize={() => setFontSize(fontSize - 2)}
                        resetFontSize={() => setFontSize(16)}
                        eventId={eventId}
                    />
                </Box>

                <main className={css.main}>
                    <QuestionSection eventId={eventId} />
                    <Chat eventId={eventId} />
                </main>
            </Box>
            <div className={css.drawer} style={drawerStyle}>
                <Box className={css.drawer_header}>
                    <IconButton
                        onClick={toggleDrawer(!openDrawer)}
                        size="large"
                    >
                        {openDrawer ? (
                            <Tooltip title={t('menu.tooltip.close')}>
                                <ChevronRightIcon />
                            </Tooltip>
                        ) : (
                            <Tooltip title={t('menu.tooltip.open')}>
                                <ChevronLeftIcon />
                            </Tooltip>
                        )}
                    </IconButton>
                </Box>
                <Divider />
                <Box>
                    <List>
                        {menuItems.map((item, index) => (
                            <div key={index}>
                                {item?.divider === true && <Divider />}
                                {item?.divider === undefined && (
                                    <ListItem
                                        disablePadding
                                        onClick={item.callback}
                                    >
                                        <Tooltip
                                            title={t(
                                                'menu.tooltip.' + item.name,
                                            )}
                                        >
                                            <ListItemButton>
                                                <ListItemIcon sx={btnStyle}>
                                                    {item.icon}
                                                </ListItemIcon>
                                                {openDrawer && (
                                                    <ListItemText
                                                        primary={t(
                                                            'menu.link.' +
                                                                item.name,
                                                        )}
                                                    />
                                                )}
                                            </ListItemButton>
                                        </Tooltip>
                                    </ListItem>
                                )}
                            </div>
                        ))}
                    </List>
                </Box>
            </div>
            <SettingsDialog />
        </Box>
    );
}

export default Editor;
