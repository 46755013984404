import React from 'react';
import css from './Comment.module.scss';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteComment, handleResponseErrors } from '../utils/apiClient';
import { useAtomValue } from 'jotai/index';
import { userAtom } from '../Atom/UserAtom';
import { assertNotNull } from '../utils/assert';
import { t } from 'i18next';
import { isAdmin } from '../utils/decider';

export default function Comment({ comment: { body, id, createdAt, account } }) {
    const userData = useAtomValue(userAtom);

    let readOnly = true;

    try {
        assertNotNull(userData?.id);
        assertNotNull(account?.id);
        readOnly = false === (account.id === userData.id || isAdmin(userData));
    } catch (error) {
        readOnly = true;
    }

    const handleDelete = () => {
        deleteComment(id).catch((error) => handleResponseErrors(error));
    };

    return (
        <div className={css.container}>
            <div className={css.headline}>
                <div>{readOnly ? account?.email : t('comment.own_name')}:</div>
                <div>{createdAt}</div>
            </div>
            <div className={css.body}>
                {body}
                <div className={css.actions}>
                    {readOnly === false && (
                        <Tooltip title={t('tooltip.comment_delete')}>
                            <IconButton size="small" onClick={handleDelete}>
                                <DeleteIcon size="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
}
