import React from 'react';
import Comment from '../Comment/Comment';
import css from './CommentList.module.scss';
import { assertNotEmpty } from '../utils/assert';
import CommentIcon from '@mui/icons-material/Comment';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { t } from 'i18next';

export default function CommentList({ comments, state, index }) {
    const [expanded, setExpanded] = React.useState(
        state === 'published' && index === 0,
    );

    try {
        assertNotEmpty(comments);
    } catch (e) {
        return <></>;
    }

    function handleExpandClick() {
        setExpanded(!expanded);
    }

    return (
        <div className={css.wrapper}>
            <div className={css.headline}>
                <div>
                    {expanded ? (
                        <ExpandLess onClick={handleExpandClick} />
                    ) : (
                        <ExpandMore onClick={handleExpandClick} />
                    )}
                </div>
                <div>{t('commentsList.headline')}</div>
                <div>
                    <Tooltip title={t('tooltip.comment_open')}>
                        <IconButton
                            size="small"
                            onClick={handleExpandClick}
                            style={{ marginRight: '8px', padding: 0 }}
                        >
                            <Badge
                                badgeContent={comments.length}
                                color="primary"
                            >
                                <CommentIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {expanded &&
                comments?.map((comment, index) => (
                    <Comment
                        key={index}
                        comment={comment}
                        expanded={state !== 'archived'}
                    />
                ))}
        </div>
    );
}
