import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from '../translation/en.json';
import translation_de from '../translation/de.json';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: { translation: translation_en },
            de: { translation: translation_de },
        },
        lng: 'de',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span', 'b'],
        },
    });

export default i18n;
