export const assertNotNull = (value, message) => {
    if (undefined === value || null === value) {
        throw new Error(message ?? 'Value is null or undefined');
    }
};
export const assertNull = (value, message) => {
    if (undefined !== value && null !== value) {
        throw new Error(message ?? 'Value is not null or undefined');
    }
};

export const assertArrayContains = (array, value, message) => {
    assertNotEmpty(array, message);
    assertNotEmpty(value, message);
    if (array.indexOf(value) === -1) {
        throw new Error(message ?? 'Value is null or undefined');
    }
};
export const assertNotEmpty = (value, message) => {
    assertNotNull(value, message);
    if ('' === value) {
        throw new Error(message ?? 'Value is not empty');
    }
    if (0 === value.length) {
        throw new Error(message ?? 'Value is not empty');
    }
};
export const assertFalse = (expression, message) => {
    if (true === expression) {
        throw new Error(message ?? 'Value is not true');
    }
};

export const assertTrue = (expression, message) => {
    if (false === expression) {
        throw new Error(message ?? 'Value is not true');
    }
};

export const assertUuid4 = (value, message) => {
    if (
        !value.match(
            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
        )
    ) {
        throw new Error(message ?? 'Uuid4 expected, got ' + value);
    }
};
