import { atom } from 'jotai';

import dayjs from 'dayjs';

export const userAtom = atom(null);

export const loginStateAtom = atom(false);

export const chatOpenAtom = atom(false);
export const settingsDialog = atom(false);
export const layoutAtom = atom(
    new URLSearchParams(window.location.search).get('layout') ?? 'three',
);
export const chatActiveAtom = atom(
    new URLSearchParams(window.location.search).get('chat') !== 'no',
);

export const timerAtom = atom(null);

export const isPausedAtom = atom((get) => {
    const timer = get(timerAtom);
    return timer !== null && timer?.active === false;
});

export const isActiveAtom = atom((get) => {
    const timer = get(timerAtom);
    return (
        timer !== null &&
        timer?.active === true &&
        parseInt(timer?.start) + timer?.duration > dayjs().unix()
    );
});

export const isCreateAtom = atom(
    (get) => get(isPausedAtom) === false && get(isActiveAtom) === false,
);

export const eventAtom = atom(null);
