import { useEffect } from 'react';
import { loadUserData } from './apiClient';
import { useAtom } from 'jotai/index';
import { userAtom } from '../Atom/UserAtom';
import { assertNull } from './assert';

export default function useLogin() {
    const [userData, setUserData] = useAtom(userAtom);

    useEffect(() => {
        try {
            assertNull(userData);

            loadUserData()
                .then((data) => {
                    setUserData(data?.data?.account);
                })
                .catch(() => {});
        } catch (e) {
            // intentionally empty
        }
        return () => {};
    }, [userData]);

    return [userData];
}
