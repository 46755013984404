import React from 'react';
import style from './InfoText.module.scss';

export default function InfoText({ type = 'info', children }) {
    return (
        <div className={`${style[type]} ${style.wrapper}`}>
            <div className={style.infoText}>{children}</div>
        </div>
    );
}
