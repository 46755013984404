export function insertAfterId(array, id, newItem) {
    const index = array.findIndex((item) => item.id === id);

    if (index === -1) {
        return array;
    }

    return [...array.slice(0, index + 1), newItem, ...array.slice(index + 1)];
}

export function insertBeforeId(array, id, newItem) {
    const index = array.findIndex((item) => item.id === id);

    if (index === -1) {
        return array;
    }

    return [...array.slice(0, index), newItem, ...array.slice(index)];
}
