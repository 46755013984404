import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonGroup, DialogContentText } from '@mui/material';
import 'dayjs/locale/de';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import dayjs from 'dayjs';
import css from './TimerEditForm.module.scss';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { combineClasses } from '../utils/classNames';
import InfoIcon from '@mui/icons-material/Info';
import { useAtom, useAtomValue } from 'jotai';
import {
    isActiveAtom,
    isCreateAtom,
    isPausedAtom,
    timerAtom,
} from '../Atom/UserAtom';
import {
    createTimer,
    deleteTimer,
    loadTimer,
    patchTimer,
} from '../utils/apiClient';
import {
    assertNotEmpty,
    assertNotNull,
    assertNull,
    assertTrue,
    assertUuid4,
} from '../utils/assert';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { PauseCircle } from '@mui/icons-material';

export default function TimerEditForm({ open, eventId, onClose }) {
    const { t } = useTranslation();
    const [interval, setInterval] = useState(300);
    const [timer, setTimer] = useAtom(timerAtom);
    const isActive = useAtomValue(isActiveAtom);
    const isPaused = useAtomValue(isPausedAtom);
    const isCreate = useAtomValue(isCreateAtom);

    const ranges = [180, 300, 600, 900];

    // Berechnung der verbleibenden Stunden und Minuten
    const remainingHours = Math.floor(interval / 3600);
    const remainingMinutes = Math.floor((interval % 3600) / 60);

    const calculateEndDate = () => {
        try {
            assertNotNull(timer?.start);

            return dayjs(
                dayjs.unix(timer?.start).add(timer?.duration, 'second'),
            );
        } catch (e) {
            // intentionally emtpy
        }
    };

    const timerEndDate = calculateEndDate();
    // const isActive = null !== timer
    //                  && timer?.active === true
    //                  && (
    //                      parseInt(timer?.start) + timer?.duration
    //                  ) > dayjs()
    //                      .unix();
    //
    // const isPaused = (
    //     null !== timer && timer?.active === false
    // );
    //
    // const isCreate = (
    //     isPaused === false && isActive === false
    // );

    useEffect(() => {
        try {
            assertNotNull(eventId);
            assertUuid4(eventId);
        } catch (e) {
            return;
        }

        if (open === true) {
            loadTimer(eventId)
                .then((response) => {
                    try {
                        assertNotEmpty(response?.data);
                    } catch (e) {
                        return;
                    }

                    setTimer(response?.data[0]);
                    setInterval(response?.data[0]?.duration);
                })
                .catch(() => {
                    setTimer(null);
                });
        }

        return () => {};
    }, [open]);

    function handleTimerStart(start) {
        try {
            assertNotNull(start);
            assertNotNull(interval);
            assertTrue(interval > 0);
            assertNull(timer?.active);
            createTimer(eventId, interval, start.format('DD.MM.YYYY HH:mm:ss'))
                .then((response) => {
                    try {
                        assertNotEmpty(response?.data);
                    } catch (e) {
                        return;
                    }
                    setTimer(response?.data);
                })
                .catch(() => {
                    // intentionally empty
                });
        } catch (e) {
            handleTimerUpdate(dayjs());
        }
    }

    function handleTimePickerUpdate(selectedDate) {
        let diff = 0;
        try {
            assertNotNull(selectedDate);

            diff = selectedDate.second(0).diff(dayjs(), 'second') ?? 300;

            assertNotNull(diff);
            assertTrue(diff > 0);
        } catch (e) {
            setInterval(0);
            return;
        }

        if (diff <= 60) {
            setInterval(60);
            return;
        }

        setInterval(diff);
    }

    function handleTimerStop() {
        try {
            assertNotNull(timer?.id);
            assertUuid4(timer?.id);

            deleteTimer(timer.id).catch(() => {
                setTimer(null);
            });
        } catch (e) {
            setTimer(null);
        }
    }

    function handleTimerUpdate(now) {
        patchTimer({
            id: timer.id,
            active: true,
            duration: interval,
            start: now,
        })
            .then((response) => {
                setTimer(response?.data);
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    setTimer(null);
                }
            });
    }

    function handleTimerPause(now) {
        let diff =
            timer?.duration -
            now.diff(dayjs.unix(parseInt(timer.start)), 'second');

        if (diff < 0) {
            diff = 0;
        }

        patchTimer({
            id: timer.id,
            active: false,
            duration: diff,
            start: now,
        })
            .then((response) => {
                setTimer(response?.data);
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    setTimer(null);
                }
            });
    }

    function handleTimerResume(now) {
        patchTimer({
            id: timer.id,
            active: true,
            start: now,
        })
            .then(() => {
                // setTimer(response?.data);
            })
            .catch(() => {});
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {isActive && t('dialog.timer.headline_active')}
                {isPaused && t('dialog.timer.headline_paused')}

                {isCreate && t('dialog.timer.headline_paused')}
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="de"
                >
                    {isCreate && (
                        <>
                            <DialogContentText
                                className={combineClasses(
                                    css.wrapper,
                                    css.text,
                                )}
                            >
                                {t('dialog.timer.info.time_range')}
                                <br />
                            </DialogContentText>
                            <div
                                className={combineClasses(
                                    css.wrapper,
                                    css.input,
                                )}
                            >
                                <ButtonGroup variant="contained" color="info">
                                    {ranges.map((seconds, key) => (
                                        <Button
                                            key={key}
                                            onClick={() => setInterval(seconds)}
                                        >
                                            {seconds / 60} m
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </div>

                            <div
                                className={combineClasses(
                                    css.wrapper,
                                    css.text,
                                )}
                            >
                                {t('dialog.timer.info.fixed_time')}
                            </div>
                            <div
                                className={combineClasses(
                                    css.wrapper,
                                    css.input,
                                )}
                            >
                                <TimePicker
                                    label={t('dialog.timer.input_label')}
                                    adapter={AdapterDayjs}
                                    views={['hours', 'minutes']}
                                    format="HH:mm"
                                    onChange={handleTimePickerUpdate}
                                />
                            </div>
                            <div
                                className={combineClasses(
                                    css.wrapper,
                                    css.info,
                                )}
                            >
                                <span style={{ width: '100%' }}>
                                    <InfoIcon fontSize="small" color="info" />
                                    &nbsp;
                                    {t('dialog.timer.info_text_pre')}
                                    &nbsp;
                                    {remainingHours > 0 && (
                                        <Trans
                                            count={remainingHours}
                                            values={{ hours: remainingHours }}
                                            i18nKey="dialog.timer.info_text_hours"
                                        />
                                    )}
                                    {remainingHours > 0 &&
                                        remainingMinutes > 0 && (
                                            <span>&nbsp;und&nbsp;</span>
                                        )}
                                    <span>
                                        {remainingMinutes > 0 && (
                                            <Trans
                                                count={remainingMinutes}
                                                values={{
                                                    minutes: remainingMinutes,
                                                }}
                                                i18nKey="dialog.timer.info_text_minutes"
                                            />
                                        )}
                                        &nbsp;
                                        {t('dialog.timer.minutes_active')}
                                    </span>
                                </span>
                            </div>
                        </>
                    )}
                    {isActive && (
                        <DialogContentText>
                            <Trans
                                i18nKey="dialog.timer.end_msg"
                                values={{ date: timerEndDate.format('HH:mm') }}
                            />
                        </DialogContentText>
                    )}

                    <DialogContentText className={css.actions}>
                        {isCreate && (
                            <Button
                                variant="contained"
                                color="success"
                                startIcon={<PlayCircleFilledIcon />}
                                onClick={() => handleTimerStart(dayjs())}
                            >
                                {t('dialog.timer.btn_start')}
                            </Button>
                        )}
                        {isActive && (
                            <>
                                <Button
                                    variant="contained"
                                    color="error"
                                    startIcon={<StopCircleIcon />}
                                    onClick={handleTimerStop}
                                >
                                    {t('dialog.timer.btn_stop')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    startIcon={<PauseCircle />}
                                    onClick={() => handleTimerPause(dayjs())}
                                >
                                    {t('dialog.timer.btn_pause')}
                                </Button>
                            </>
                        )}

                        {isPaused && (
                            <>
                                <Button
                                    variant="contained"
                                    color="error"
                                    startIcon={<StopCircleIcon />}
                                    onClick={handleTimerStop}
                                >
                                    {t('dialog.timer.btn_stop')}
                                </Button>

                                <Button
                                    variant="contained"
                                    color="info"
                                    startIcon={<NotStartedIcon />}
                                    onClick={() => handleTimerResume(dayjs())}
                                >
                                    {t('dialog.timer.btn_resume')}
                                </Button>
                            </>
                        )}
                    </DialogContentText>
                </LocalizationProvider>
            </DialogContent>
        </Dialog>
    );
}
