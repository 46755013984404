import React from 'react';
import LoadingIndicator from './LoadingIndicator';

export default function Form({ children, action }) {
    async function handleSubmit(event) {
        const data = Object.fromEntries(event.entries());
        await action(data, event);
    }

    return (
        <form action={handleSubmit}>
            <LoadingIndicator />
            {children}
        </form>
    );
}
