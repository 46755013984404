import Box from '@mui/material/Box';
import style from './Iframe.module.scss';
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Submit from '../../Form/Submit';
import Form from '../../Form/Form';
import InfoText from '../../Block/InfoText';
import { getEventById, newQuestion } from '../../utils/apiClient';
import { useParams } from 'react-router-dom';
import '../../Layout/_reset.scss';

export default function Iframe() {
    const { t } = useTranslation();
    const [pending, setPending] = useState(false);
    const [event, setEvent] = useState(null);
    const { eventId } = useParams();

    const active = pending !== true && event?.published !== false;

    useEffect(() => {
        getEventById(eventId).then((event) => {
            setEvent(event.data);
        });
    }, []);

    async function handleSubmit(data) {
        setPending(true);

        data.event = '/api/events/' + eventId;

        await newQuestion(data);
        setPending(false);
        // return delay(3000)
        //     .then(() => setPending(false));
    }

    return (
        <Box className={style.wrapper}>
            <div>
                <h1>{event?.name}:</h1>
                {event?.published === false && (
                    <InfoText type="box">
                        <Trans
                            i18nKey="iframe.event_unpublished"
                            values={{ ...event }}
                        />
                    </InfoText>
                )}

                <Form action={handleSubmit} disabled={!active}>
                    <TextField
                        label={t('iframe.input.participant')}
                        name="participant"
                        fullWidth
                        className={'test'}
                        disabled={!active}
                        required
                    />
                    &nbsp;
                    <TextField
                        name="body"
                        label={t('iframe.input.message')}
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={10}
                        disabled={!active}
                        required
                    />
                    <div className={style.actions}>
                        <Submit disabled={!event?.published} />
                    </div>
                </Form>
            </div>
        </Box>
    );
}
