import React from 'react';
import { useFormStatus } from 'react-dom';
import { Button } from '@mui/material';
import { t } from 'i18next';

export default function Submit({ disabled = false }) {
    const { pending } = useFormStatus();

    return (
        <Button
            variant="contained"
            color="success"
            type="submit"
            loading={pending}
            disabled={disabled}
        >
            {t('dialog.btn.submit')}
        </Button>
    );
}
