import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { chatOpenAtom } from '../Atom/UserAtom';

export default function SpeedDialTooltip({ hide = false, actions }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const chatOpen = useAtomValue(chatOpenAtom);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    if (hide || chatOpen) {
        return <></>;
    }

    return (
        <Box sx={{ flexGrow: 1, zIndex: 900 }}>
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip"
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 900,
                }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={t(action.name)}
                        tooltipOpen
                        onClick={action.onClick}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}
