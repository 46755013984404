import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Alert, DialogContentText } from '@mui/material';

export default function CommentForm({
    body = '',
    open = false,
    onClose,
    handleSubmit,
    warning = null,
}) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            disableEscapeKeyDown
            maxWidth={'lg'}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>{t('dialog.title.comment_form')}</DialogTitle>
            <DialogContent>
                {warning?.violations && (
                    <Alert severity="warning">
                        <ul>
                            {warning.violations.map((violation, key) => (
                                <li key={key}>{t(violation.message)}</li>
                            ))}
                        </ul>
                    </Alert>
                )}
                <DialogContentText></DialogContentText>
                <br />
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="body"
                    name="body"
                    label={t('dialog.input_comment.title')}
                    multiline
                    fullWidth
                    defaultValue={body}
                    minRows={3}
                    maxRows={10}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('dialog.btn.cancel')}</Button>
                <Button type="submit">{t('dialog.btn.save')}</Button>
            </DialogActions>
        </Dialog>
    );
}
