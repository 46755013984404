// nothing special, just remove some noise from templating
export function classNames(classes) {
    return Object.entries(classes)
        .filter(([, value]) => value)
        .map(([key]) => key)
        .join(' ');
}

export function combineClasses(...classes) {
    return classes.join(' ');
}
