import React from 'react';
import { Button } from '@mui/material';
import { t } from 'i18next';

export default function WhiteButton({ text, action }) {
    return (
        <Button
            variant="outlined"
            size="small"
            sx={{ color: 'white', borderColor: 'white' }}
            onClick={action}
        >
            {t(text)}
        </Button>
    );
}
