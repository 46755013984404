import React from 'react';
import css from './Navbar.module.scss';
import { FormatClear, TextDecrease, TextIncrease } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Timer from '../Timer/Timer';
import { useTranslation } from 'react-i18next';

export default function Navbar({
    title,
    increaseFontSize,
    decreaseFontSize,
    resetFontSize,
    eventId,
}) {
    const { t } = useTranslation();

    return (
        <Box component="nav" className={css.nav}>
            <div>
                <h1>{title}</h1>
            </div>
            <Timer eventId={eventId} />
            <div className={css.menu}>
                <div>
                    <Tooltip title={t('tooltip.decrease_font_size')}>
                        <IconButton
                            variant="outlined"
                            sx={{ color: 'white' }}
                            onClick={decreaseFontSize}
                        >
                            <TextDecrease />
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title={t('tooltip.reset_font_size')}>
                        <IconButton
                            sx={{ color: 'white' }}
                            onClick={resetFontSize}
                        >
                            <FormatClear />
                        </IconButton>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title={t('tooltip.increase_font_size')}>
                        <IconButton
                            sx={{ color: 'white' }}
                            onClick={increaseFontSize}
                        >
                            <TextIncrease />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </Box>
    );
}
