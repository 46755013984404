import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { IconButton, Tooltip } from '@mui/material';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import css from './Draggable.module.scss';
import { useTranslation } from 'react-i18next';

export default function Draggable({
    id,
    element,
    children,
    data: { participant, body, comments, state },
}) {
    const { t } = useTranslation();

    const Element = element || 'div';
    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: id,
        data: {
            type: 'sort',
            id: id,
            participant: participant,
            body: body,
            comments: comments,
            state: state,
        },
    });
    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              zIndex: 500,
              position: 'relative',
          }
        : {
              position: 'relative',
          };

    const btnStyle = transform ? { display: 'none' } : {};

    btnStyle.position = 'absolute';
    btnStyle.left = '10px';
    btnStyle.top = '10px';

    return (
        <Element
            id={id}
            ref={setNodeRef}
            style={style}
            className={css.draggable}
        >
            <Tooltip title={t('tooltip.question_move')}>
                <IconButton
                    className={css.button}
                    {...listeners}
                    {...attributes}
                    style={btnStyle}
                >
                    <OpenWithIcon />
                </IconButton>
            </Tooltip>
            {children}
        </Element>
    );
}
