import React from 'react';
import RowTitle from '../Block/RowTitle';
import css from './QuestionRow.module.scss';
import Question from './Question';
import { useDroppable } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Draggable from '../DnD/Draggable';

export default function QuestionRow({
    id,
    title,
    items,
    onDialogOpen,
    highlight,
}) {
    const { isOver, setNodeRef } = useDroppable({
        id: id,
        data: {
            type: 'move',
            state: id,
        },
        disabled: items?.length > 0,
    });

    const style = {
        backgroundColor: isOver ? 'rgba(0,0,0,.1)' : undefined,
    };

    return (
        <div className={css.container}>
            <div className={css.rowTitle}>
                <RowTitle title={title} />
            </div>

            <SortableContext
                id={id}
                items={items ?? []}
                strategy={verticalListSortingStrategy}
            >
                <div
                    className={css.question_row}
                    ref={setNodeRef}
                    style={style}
                >
                    {items?.length > 0 &&
                        items.map((item, index) => (
                            <Draggable id={item.id} key={index} data={item}>
                                <Question
                                    index={index}
                                    id={item.id}
                                    item={item}
                                    handleClickOpen={() => onDialogOpen(item)}
                                    highlight={highlight}
                                />
                            </Draggable>
                        ))}
                </div>
            </SortableContext>
        </div>
    );
}
