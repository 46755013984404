import { useSetAtom } from 'jotai';
import { userAtom } from '../Atom/UserAtom';

export function useLogout() {
    const setUserData = useSetAtom(userAtom);

    return function () {
        setUserData(null);
        window.location.href = '/logout';
    };
}
