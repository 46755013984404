import React from 'react';
import Box from '@mui/material/Box';
import styles from './NoPage.module.scss';
import '../utils/i18next';
import { useTranslation } from 'react-i18next';

function NoPage() {
    const [t] = useTranslation();

    return (
        <Box component="main" className={styles.container}>
            <Box>
                <h1>{t('error.page.headline', { code: 404 })}</h1>
                <h2>{t('error.page.slogan')}</h2>
            </Box>

            <Box>
                <a href="/">{t('Go back to home')}</a>
            </Box>
        </Box>
    );
}

export default NoPage;
