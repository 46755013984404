import * as Sentry from '@sentry/react';

Sentry.init({
    release: '0.0.1',
    // eslint-disable-next-line
    environment: process.env.NODE_ENV,
    dsn: 'https://c60ab648e7dcaddd15c7d965212177e5@o4507081880895488.ingest.de.sentry.io/4509045800829008',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.browserProfilingIntegration(),
    ],
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [/https*:\/\/localhost\//],
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
});
