import React, { useRef, useState } from 'react';
import MessageList from './MessageList';
import css from './Chat.module.scss';
import ChatMessageInput from './ChatMessageInput';
import { Badge, IconButton } from '@mui/material';
import { t } from 'i18next';
import Box from '@mui/material/Box';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MailIcon from '@mui/icons-material/Mail';
import { useAtom, useAtomValue } from 'jotai';
import { chatActiveAtom, chatOpenAtom } from '../Atom/UserAtom';

export default function Chat({ eventId }) {
    const chatActive = useAtomValue(chatActiveAtom);
    const [open, setOpen] = useAtom(chatOpenAtom);
    const [, setCount] = useState([]);
    const [lastMessage, setLastMessage] = useState(null);
    let readCount = useRef(0);

    const showChat = useAtomValue(chatActiveAtom);
    const style = {};

    if (false === chatActive) {
        return <></>;
    }

    const handleReadCount = () => {
        if (open) {
            return;
        }

        readCount.current = readCount.current + 1;
        setCount(readCount.current);
    };

    const handleLastMessage = (message) => {
        if (open) {
            return;
        }

        setLastMessage(message);
    };

    function toggleVisible() {
        if (open) {
            readCount.current = 0;
            setCount(readCount.current);
        }
        setOpen(!open);
        setLastMessage(null);
    }

    if (showChat === 'no') {
        return <></>;
    }

    style.transform = 'translateY(95dvh)';

    if (open) {
        style.transform = 'none';
        style.height = '100dvh';
        style.width = '100vw';
    }

    return (
        <Box className={css.chat} sx={style}>
            <div className={css.headline}>
                <div>
                    <IconButton onClick={toggleVisible} size="small">
                        {open ? (
                            <KeyboardArrowDownIcon />
                        ) : (
                            <KeyboardArrowUpIcon />
                        )}
                    </IconButton>
                </div>
                {open || (
                    <div>
                        <Badge badgeContent={readCount.current} color="warning">
                            <MailIcon color="primary" size="small" />
                        </Badge>
                    </div>
                )}

                {open || lastMessage === null ? (
                    <div>
                        <h3>{t('chat.headline')}</h3>
                    </div>
                ) : (
                    <div>
                        <span>{lastMessage?.account?.email}:</span>
                        <span>{lastMessage?.body}</span>
                    </div>
                )}
            </div>

            <MessageList
                eventId={eventId}
                handleReadCount={handleReadCount}
                handleLastMessage={handleLastMessage}
            />
            <ChatMessageInput eventId={eventId} />
        </Box>
    );
}
