export default function mercure(topicList = ['*'], lastEventId) {
    const u = new URL(window.location.origin + '/mc/.well-known/mercure');

    topicList.forEach((topic) => u.searchParams.append('topic', topic));

    if (lastEventId !== null && lastEventId?.value !== null) {
        u.searchParams.append('lastEventID', lastEventId?.value);
    }

    return new EventSource(u, { withCredentials: true });
}
