import React, { useEffect, useState } from 'react';
import QuestionRow from './QuestionRow';
import css from './QuestionSection.module.scss';
import { useTranslation } from 'react-i18next';
import {
    deleteQuestion,
    handleResponseErrors,
    loadQuestion,
    loadQuestionCollection,
    newQuestion,
    patchQuestion,
    postComment,
} from '../utils/apiClient';
import QuestionCreateForm from './QuestionCreateForm';
import QuestionDialog from '../Block/QuestionDialog';
import mercure from '../utils/mercure';
import SpeedDialTooltip from '../Block/SpeedDialTooltip';
import CommentIcon from '@mui/icons-material/Comment';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CommentForm from './CommentForm';
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    pointerWithin,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import Question from './Question';
import { insertAfterId, insertBeforeId } from '../utils/insertBeforeId';
import { assertFalse, assertNotNull, assertTrue } from '../utils/assert';
import { useAtomValue } from 'jotai';
import { layoutAtom } from '../Atom/UserAtom';
import TimerEditForm from '../Timer/TimerEditForm';

export default function QuestionSection({ eventId }) {
    const { t } = useTranslation();
    const [questions, setQuestions] = useState(null);
    const [editDialog, setEditDialog] = useState(false);
    const [commentDialog, setCommentDialog] = useState(false);
    const [isQuestionDialogOpen, setQuestionDialogOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [editItem, setEditItem] = useState();
    const [questionFormWarning, setQuestionFormWarning] = useState(false);

    const [movedItem, setMovedItem] = useState(null);
    const [update, setUpdate] = useState(Date.now());
    const [timerEditForm, setTimerEditForm] = useState(false);
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor),
    );

    const layout = useAtomValue(layoutAtom);
    const showArchive = layout === 'three';
    const showNew = ['two', 'three'].includes(layout);

    useEffect(() => {
        loadQuestionCollection(eventId)
            .then((response) => {
                setUpdate(Date.now());
                setQuestions(response);
            })
            .catch(() => {
                // @todo add error handling
            });

        const mercureEvent = mercure(['/api/events/' + eventId + '/questions']);
        mercureEvent.onmessage = () => {
            loadQuestionCollection(eventId)
                .then((response) => {
                    setQuestions(response);
                })
                .catch((error) => handleResponseErrors(error));
        };

        return () => {
            try {
                mercureEvent?.close();
            } catch (e) {
                // intentional empty
            }
        };
    }, [eventId]);

    const newQuestions =
        questions?.filter((item) => item.state === 'draft') ?? [];

    const activeQuestions =
        questions?.filter((item) => item.state === 'published') ?? [];

    const archivedQuestions =
        questions?.filter((item) => item.state === 'archived') ?? [];

    const handleCloseQuestionDialog = (value) => {
        setQuestionDialogOpen(false);

        switch (value) {
            case 'dialog.edit':
                setEditDialog(true);
                break;

            case 'dialog.delete':
                deleteQuestion(editItem?.id);
                break;

            case 'dialog.comment':
                setCommentDialog(true);
                break;

            case 'dialog.move_lecturer':
                patchQuestion({
                    id: editItem.id,
                    state: 'published',
                    answered: false,
                }).catch((e) => {
                    handleResponseErrors(e);
                });
                break;

            case 'dialog.answered':
                patchQuestion({
                    id: editItem.id,
                    state: 'archived',
                    answered: true,
                }).catch((e) => {
                    handleResponseErrors(e);
                });
                break;

            case 'dialog.move_archive':
                patchQuestion({
                    id: editItem.id,
                    state: 'archived',
                    answered: false,
                }).catch((e) => {
                    handleResponseErrors(e);
                });
                break;

            case 'dialog.move_new':
                return patchQuestion({
                    id: editItem.id,
                    state: 'draft',
                }).catch((e) => {
                    handleResponseErrors(e);
                });
        }
    };

    function handleDialogOpen(item) {
        loadQuestion(item.id)
            .then((response) => {
                setEditItem(response);
                setQuestionDialogOpen(true);
            })
            .catch((error) => handleResponseErrors(error));
    }

    const speedDialActions = [
        {
            icon: <CommentIcon />,
            name: 'speedDial.question',
            onClick: () => {
                setEditItem(null);
                setEditDialog(true);
                setQuestionDialogOpen(false);
            },
        },
        {
            icon: <AccessTimeFilledIcon />,
            name: 'speedDial.timer',
            onClick: () => {
                setEditDialog(false);
                setTimerEditForm(true);
                setQuestionDialogOpen(false);
            },
        },
    ];

    async function handleQuestionDragEnd(event) {
        const { active, over } = event;
        const target = over?.data?.current ?? null;
        const source = active?.data?.current ?? null;
        const index = questions.findIndex((item) => item.id === source.id);

        setMovedItem(null);

        try {
            assertTrue(index !== -1);
            assertNotNull(target);
            assertNotNull(active);
        } catch (e) {
            return;
        }

        if (target?.type === 'move') {
            questions.at(index).state = target.state;
            questions.at(index).sort = source.sortable.items.length - 1;

            setUpdate(Date.now());
            setQuestions(questions);

            await patchQuestion({
                id: active.id,
                sort: source.sortable.items.length - 1,
                state: target.state,
                answered: target.state === 'archived' ? active.answered : false,
            }).catch((error) => {
                handleResponseErrors(error);
            });
            return;
        }

        target.sortable.items.forEach((itemId, key) => {
            const srcItem =
                questions.find((question) => question?.id === itemId) ?? null;

            try {
                assertNotNull(srcItem);
                assertNotNull(srcItem?.sort);
                assertFalse(srcItem.sort === key && srcItem.id !== source.id);
            } catch (e) {
                return;
            }

            patchQuestion({
                id: srcItem.id,
                sort: key,
                state: target.state,
                answered: target.state === 'archived' ? active.answered : false,
            }).catch((e) => {
                handleResponseErrors(e);
            });
        });
    }

    function handleQuestionDragStart(event) {
        setMovedItem(event.active.data.current);
    }

    function handleDragOver(event) {
        const { active, over } = event;
        const target = over?.data?.current ?? null;
        const source = active?.data?.current ?? null;
        const index = questions.findIndex((item) => item.id === active.id);

        try {
            assertTrue(index !== -1);
            assertNotNull(target);
        } catch (e) {
            return;
        }

        if (target.type === 'move') {
            questions.at(index).state = target.state;
            questions.at(index).sort = source.sortable.items.length - 1;

            setUpdate(Date.now());
            setQuestions(questions);
            return;
        }

        const destPosition = target.sortable.index;
        const originPosition = source.sortable.index;

        if (destPosition === originPosition && target.state === source.state) {
            return;
        }

        let filteredQuestions = questions.filter(
            (item) => item.id !== source.id,
        );

        const sourceItem = questions.find((item) => item.id === source.id);
        sourceItem.state = target.state;
        let newArray = [];

        if (destPosition <= originPosition) {
            newArray = insertBeforeId(filteredQuestions, target.id, sourceItem);
        } else {
            newArray = insertAfterId(filteredQuestions, target.id, sourceItem);
        }

        if (Date.now() - update > 50) {
            setUpdate(Date.now());
            setQuestions(newArray);
        }
    }

    return (
        <div className={css.container}>
            <SpeedDialTooltip actions={speedDialActions} />

            <DndContext
                sensors={sensors}
                onDragEnd={handleQuestionDragEnd}
                onDragStart={handleQuestionDragStart}
                onDragOver={handleDragOver}
                collisionDetection={pointerWithin}
            >
                {showNew ? (
                    <QuestionRow
                        title={t('questions.headline.new')}
                        id="draft"
                        items={newQuestions}
                        onDialogOpen={handleDialogOpen}
                        highlight={movedItem}
                    />
                ) : (
                    ''
                )}
                <QuestionRow
                    title={t('questions.headline.active')}
                    id="published"
                    items={activeQuestions}
                    onDialogOpen={handleDialogOpen}
                    highlight={movedItem}
                />

                {showArchive && (
                    <QuestionRow
                        title={t('questions.headline.archive')}
                        id="archived"
                        items={archivedQuestions}
                        onDialogOpen={handleDialogOpen}
                        highlight={movedItem}
                    />
                )}
                <DragOverlay
                    style={{
                        pointerEvents: 'none',
                        boxShadow: '0 0 8px rgba(0,0,0,0.3)',
                        visibility: movedItem ? 'visible' : 'hidden',
                    }}
                >
                    {movedItem && (
                        <Question
                            item={movedItem}
                            highlight={false}
                            test="123"
                        />
                    )}
                </DragOverlay>
            </DndContext>

            <CommentForm
                open={commentDialog}
                onClose={() => setCommentDialog(false)}
                handleSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());

                    formJson.question = '/api/questions/' + editItem?.id;
                    postComment(formJson)
                        .then(() => {
                            setCommentDialog(false);
                        })
                        .catch((error) => {
                            handleResponseErrors(error);
                        });
                }}
            />

            <TimerEditForm
                eventId={eventId}
                open={timerEditForm}
                onClose={() => setTimerEditForm(false)}
            />
            <QuestionCreateForm
                open={editDialog}
                onClose={() => setEditDialog(false)}
                participant={editItem?.participant}
                body={editItem?.body}
                warning={questionFormWarning}
                handleSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    if (editItem === null) {
                        formJson.event = '/api/events/' + eventId;
                        formJson.sort = 0;
                        newQuestion(formJson)
                            .then(() => {
                                setEditItem(null);
                                setEditDialog(false);
                                setQuestionDialogOpen(false);
                                setSelectedValue(null);
                            })
                            .catch((error) => {
                                handleResponseErrors(error);
                                if (error?.status === 422) {
                                    setQuestionFormWarning(error.response.data);
                                }
                            });
                    } else {
                        formJson.id = editItem.id;

                        patchQuestion(formJson)
                            .then(() => {
                                handleCloseQuestionDialog();
                                setEditDialog(false);
                            })
                            .catch((e) => {
                                handleResponseErrors(e);
                            });
                    }
                }}
            />
            <QuestionDialog
                selectedValue={selectedValue}
                onClose={handleCloseQuestionDialog}
                open={isQuestionDialogOpen}
                id={editItem?.id}
                state={editItem?.state}
                title={editItem?.participant}
                body={editItem?.body}
            />
        </div>
    );
}
