import React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import TvIcon from '@mui/icons-material/Tv';
import { Comment } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function QuestionDialog({
    onClose,
    open,
    state,
    title = '',
    id,
}) {
    const { t } = useTranslation();

    // let options = [
    //     { name: 'dialog.edit', icon: <EditIcon /> },
    //     { name: 'dialog.move_archive', icon: <ArchiveIcon /> },
    //     { name: 'dialog.move_lecturer', icon: <TvIcon /> },
    //     { name: 'dialog.comment', icon: <Comment /> },
    //     { name: 'dialog.delete', icon: <DeleteIcon /> },
    // ];
    let options = [];

    if (state === 'draft') {
        options = [
            { name: 'dialog.edit', icon: <EditIcon /> },
            { name: 'dialog.comment', icon: <Comment /> },
            { name: null },
            { name: 'dialog.move_lecturer', icon: <TvIcon /> },
            { name: 'dialog.move_archive', icon: <ArchiveIcon /> },
            { name: null },
            { name: 'dialog.delete', icon: <DeleteIcon /> },
        ];
    } else if (state === 'published') {
        options = [
            { name: 'dialog.answered', icon: <CheckCircleIcon /> },
            { name: 'dialog.move_archive', icon: <ArchiveIcon /> },
            { name: null },
            { name: 'dialog.comment', icon: <Comment /> },
            { name: null },
            { name: 'dialog.move_new', icon: <ArrowBackIosNewIcon /> },
        ];
    } else if (state === 'archived') {
        options = [
            { name: 'dialog.move_lecturer', icon: <TvIcon /> },
            { name: 'dialog.move_new', icon: <ArrowBackIosNewIcon /> },
        ];
    }

    const handleClose = () => {
        onClose(id);
    };

    const handleListItemClick = (value) => {
        onClose(value, id);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <List sx={{ pt: 0 }}>
                {options.map((item, index) =>
                    item?.name === null ? (
                        <Divider key={index} />
                    ) : (
                        <ListItem disablePadding key={index}>
                            <ListItemButton
                                onClick={() => handleListItemClick(item.name)}
                            >
                                <ListItemAvatar>
                                    <Avatar>{item.icon}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={t(item.name)} />
                            </ListItemButton>
                        </ListItem>
                    ),
                )}
            </List>
        </Dialog>
    );
}
