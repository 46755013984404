import React, { useEffect, useRef, useState } from 'react';
import css from './Timer.module.scss';
import { useAtom } from 'jotai';
import {
    isActiveAtom,
    isCreateAtom,
    isPausedAtom,
    timerAtom,
} from '../Atom/UserAtom';
import {
    assertNotEmpty,
    assertNotNull,
    assertNull,
    assertTrue,
} from '../utils/assert';
import mercure from '../utils/mercure';
import { loadTimer } from '../utils/apiClient';
import dayjs from 'dayjs';
import { combineClasses } from '../utils/classNames';
import { useAtomValue } from 'jotai/index';
import PauseIcon from '@mui/icons-material/Pause';

export default function Timer({ eventId }) {
    const [timer, setTimer] = useAtom(timerAtom);
    const intervalRef = useRef(null);
    const [countDown, setCountDown] = useState(null);
    const [lastMercureEvent, setLastMercureEvent] = useState(null);
    const isActive = useAtomValue(isActiveAtom);
    const isPaused = useAtomValue(isPausedAtom);
    const isCreate = useAtomValue(isCreateAtom);

    let hours = String(Math.floor(countDown / 3600));
    let minutes = String(Math.floor((countDown % 3600) / 60));
    let seconds = String(countDown % 60);

    if (isPaused) {
        hours = String(Math.floor(timer?.duration / 3600));
        minutes = String(Math.floor((timer?.duration % 3600) / 60));
        seconds = String(timer?.duration % 60);
    }

    useEffect(() => {
        try {
            assertTrue(timer === null);
            loadTimer(eventId)
                .then((response) => {
                    if (
                        undefined !== response?.data[0] &&
                        null !== response?.data[0]
                    ) {
                        setTimer(response?.data[0]);
                    }
                })
                .catch(() => {
                    setTimer(null);
                });
        } catch (e) {
            // intentionally empty
        }
        return () => {};
    }, [timer]);

    useEffect(() => {
        try {
            assertNotEmpty(timer, 'timer is empty');
            assertTrue(timer.active, 'timer is not active');
            assertNotEmpty(countDown, 'countDown is empty');
            assertTrue(countDown > 0, 'time is not greater than 0');

            intervalRef.current = setInterval(() => {
                if (countDown <= 1) {
                    clearInterval(intervalRef.current);
                    setTimer(null);
                    setCountDown(null);
                    return;
                }
                setCountDown(countDown - 1);
            }, 1000);
        } catch (e) {
            // intentionally empty
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [countDown]);

    useEffect(() => {
        const mercureEvent = mercure(
            ['/api/events/' + eventId + '/timer'],
            lastMercureEvent ?? null,
        );

        mercureEvent.onmessage = (event) => {
            try {
                assertNotNull(event?.data);
                let data = JSON.parse(event.data);

                assertNull(data['@id'], 'is delete event');
                assertNotNull(event?.lastEventId);

                setLastMercureEvent(event.lastEventId);
                setTimer(data);
                setCountDown(data.duration);
            } catch (e) {
                setTimer(null);
                return;
            }
        };

        mercureEvent.onerror = () => {};

        return () => {
            try {
                mercureEvent?.close();
            } catch (e) {
                // intentional empty
            }
        };
    }, [eventId]);

    useEffect(() => {
        handleTimerUpdate();
    }, [timer]);

    const handleTimerUpdate = () => {
        if (null === timer || undefined === timer) {
            return;
        }
        let end = dayjs.unix(timer.start).add(timer.duration, 'second');
        let number = end.diff(dayjs(), 'second');
        if (number < 0) {
            number = 0;
        }

        setCountDown(number);
    };

    const classes = combineClasses(
        isActive ? css.active : '',
        isPaused ? css.paused : '',
        isCreate ? css.create : '',
        css.timer,
    );

    return (
        <div className={classes}>
            {(isActive || isPaused) && (
                <h4>
                    {hours > 0 && hours.padStart(2, '0') + ':'}
                    {minutes.padStart(2, '0')}:{seconds.padStart(2, '0')}
                </h4>
            )}
            {isPaused && <PauseIcon />}
        </div>
    );
}
