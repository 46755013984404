import React from 'react';
import css from './ChatMessage.module.scss';
import { useAtomValue } from 'jotai';
import { userAtom } from '../Atom/UserAtom';
import { Paper } from '@mui/material';

export default function ChatMessage({ message: { body, date, account } }) {
    const userData = useAtomValue(userAtom);
    let className = css.wrapper;
    let isOwn = false;

    if (account.email === userData?.email) {
        isOwn = true;
        className = css.message_own;
    }

    return (
        <div className={className}>
            <Paper className={css.message} elevation={1}>
                {isOwn || <div className={css.author}>{account.email}</div>}
                <div className={css.messageContent}>
                    {body}
                    <span className={css.time}>{date}</span>
                </div>
            </Paper>
            <div className={css.marker}></div>
        </div>
    );
}
