import React from 'react';
import css from './RowTitle.module.scss';
import { IconButton } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

export default function RowTitle({ title, buttonCallback }) {
    const style = {
        textAlign: 'center',
    };

    if (buttonCallback) {
        style['textAlign'] = 'left';
    }

    return (
        <div className={css.rowTitle} style={style}>
            {buttonCallback && (
                <IconButton onClick={buttonCallback} size="small">
                    <ChevronRight />
                </IconButton>
            )}
            <h3>{title}</h3>
        </div>
    );
}
