import React, { useState } from 'react';
import css from './LoginPage.module.scss';
import { Alert, Button, TextField } from '@mui/material';
import { loginUser } from '../utils/apiClient';
import { enqueueSnackbar } from 'notistack';
import { useSetAtom } from 'jotai';
import { userAtom } from '../Atom/UserAtom';
import { useLocation } from 'react-router-dom';

export default function LoginPage() {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error] = useState();
    const setUserData = useSetAtom(userAtom);

    const { pathname } = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await loginUser({
                username,
                password,
            });

            setUserData(data.user);

            if (pathname === '/app/login') {
                window.location.replace('/');
            }
        } catch (e) {
            enqueueSnackbar(
                'Fehler beim Login, bitte versuch es später noch einmal.',
                { variant: 'error' },
            );
        }
    };

    return (
        <div className={css.login}>
            <div className={css.wrapper}>
                <h1>Anmelden</h1>
                {error && <Alert severity="error">{error}</Alert>}
                <form onSubmit={handleSubmit}>
                    <TextField
                        required={true}
                        margin="dense"
                        variant="standard"
                        fullWidth={true}
                        label="E-Mail"
                        onChange={(e) => setUserName(e.target.value)}
                    />

                    <TextField
                        required={true}
                        variant="standard"
                        type="password"
                        fullWidth={true}
                        margin="normal"
                        autoComplete="current-password"
                        label="Passwort"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <br />
                    <br />
                    <br />
                    <Button
                        variant="contained"
                        fullWidth={true}
                        size="large"
                        type="submit"
                    >
                        Anmelden
                    </Button>
                </form>
            </div>
        </div>
    );
}
