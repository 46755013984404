import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import './utils/instrument';

import NoPage from './pages/NoPage';
import ReactDOM from 'react-dom/client';
import Editor from './pages/Editor/Editor';
import { SnackbarProvider } from 'notistack';
import './utils/i18next';
import Iframe from './pages/Iframe/Iframe';
import ExportIframe from './pages/ExportIframe/ExportIframe';
import LoginPage from './Login/LoginPage';

function App() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/app/:eventId" element={<Editor />}></Route>
                    <Route path="/app/q/:eventId" element={<Iframe />} />
                    <Route path="/app/i/:eventId" element={<ExportIframe />} />
                    <Route path="/app/login/" element={<LoginPage />} />
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}

export default App;

const root = ReactDOM.createRoot(document.getElementById('root'), {
    onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
        console.warn('Uncaught error', error, errorInfo.componentStack);
    }),
    // Callback called when React catches an error in an ErrorBoundary.
    onCaughtError: Sentry.reactErrorHandler(),
    // Callback called when React automatically recovers from errors.
    onRecoverableError: Sentry.reactErrorHandler(),
});
root.render(
    <SnackbarProvider
        maxSnack={3}
        autoHideDuration={5000}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
    >
        <App />
    </SnackbarProvider>,
);
