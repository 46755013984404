import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { useAtom } from 'jotai';
import { chatActiveAtom, layoutAtom, settingsDialog } from '../Atom/UserAtom';
import {
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksTwoOne from '@mui/icons-material/LooksOne';
import LooksTwoThree from '@mui/icons-material/Looks3';
import addParamToUrl from '../utils/url';
import style from './SettingsDialog.module.scss';
import { t } from 'i18next';

export default function SettingsDialog() {
    const navigate = useNavigate();
    const [layout, setLayout] = useAtom(layoutAtom);
    const [chat, setChat] = useAtom(chatActiveAtom);
    const [open, setOpen] = useAtom(settingsDialog);

    const handleLayoutChange = (event, newValue) => {
        setLayout(newValue);
        addParamToUrl('layout', newValue, navigate);
    };

    const handleChatChange = (event, newValue) => {
        setChat(newValue);
        addParamToUrl('chat', newValue ? 'yes' : 'no', navigate);
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open}>
            <DialogTitle>{t('settings.title')}</DialogTitle>
            <Box className={style.container}>
                <div>
                    <h4>{t('settings.layout.headline')}</h4>
                    <FormHelperText id="my-helper-text">
                        {t('settings.layout.help')}
                    </FormHelperText>
                    <ToggleButtonGroup
                        value={layout}
                        exclusive
                        onChange={handleLayoutChange}
                        style={{ marginTop: '10px' }}
                    >
                        <ToggleButton value="one">
                            <LooksTwoOne />
                        </ToggleButton>
                        <ToggleButton value="two">
                            <LooksTwoIcon />
                        </ToggleButton>
                        <ToggleButton value="three">
                            <LooksTwoThree />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <hr />
                <div>
                    <h4>{t('settings.chat.headline')}</h4>
                    <FormGroup>
                        <FormHelperText id="my-helper-text">
                            {t('settings.chat.help')}
                        </FormHelperText>
                        <FormControlLabel
                            labelPlacement="Start"
                            control={
                                <Switch
                                    checked={chat}
                                    onChange={handleChatChange}
                                />
                            }
                            label={
                                chat
                                    ? t('settings.chat.active')
                                    : t('settings.chat.inactive')
                            }
                        />
                    </FormGroup>
                </div>
            </Box>
        </Dialog>
    );
}
