import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import css from './ChatMessageInput.module.scss';
import { useTranslation } from 'react-i18next';
import { assertNotEmpty, assertNotNull, assertUuid4 } from '../utils/assert';
import { handleResponseErrors, postChat } from '../utils/apiClient';

export default function ChatMessageInput({ eventId }) {
    const [message, setMessage] = useState('');
    const [disableInput] = useState(false);
    const { t } = useTranslation();

    const handleFormSubmit = (event) => {
        event.preventDefault();
        try {
            assertNotNull(eventId);
            assertUuid4(eventId);
            assertNotEmpty(message);
        } catch (e) {
            return;
        }

        let obj = {
            body: message,
            event: '/api/events/' + eventId,
        };

        postChat(eventId, obj)
            .then(() => setMessage(''))
            .catch((e) => handleResponseErrors(e));
    };

    return (
        <div className={css.wrapForm}>
            <TextField
                id="standard-text"
                label={t('chat.input.message')}
                className={css.wrapText}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                size={'small'}
                onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                        handleFormSubmit(event);
                    }
                }}
            />
            <Button
                size={'small'}
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleFormSubmit}
                disabled={disableInput}
                className={css.button}
                startIcon={<SendIcon />}
            >
                {t('chat.send_btn')}
            </Button>
        </div>
    );
}
