import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import css from './Question.module.scss';
import { MenuOpen } from '@mui/icons-material';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CommentList from './CommentList';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { handleResponseErrors, patchQuestion } from '../utils/apiClient';
import { useTranslation } from 'react-i18next';

export default function Question({
    index,
    id,
    item: { participant, body, comments, state, answered },
    handleClickOpen,
    highlight,
}) {
    const { t } = useTranslation();
    let style = {};
    let showOverlay = false;

    if (highlight?.id === id && handleClickOpen) {
        showOverlay = true;
        style = {
            backgroundColor: '#efefef',
            color: '#efefef',
            borderWidth: 0,
        };
    }

    style.whiteSpace = 'pre-wrap';

    const toggleAnswered = () => {
        patchQuestion({ id, answered: !answered }).catch((e) => {
            handleResponseErrors(e);
        });
    };

    return (
        <div className={css.question} style={style}>
            {showOverlay && <div className={css.overlay}>overlay</div>}
            <div className={css.actions} style={style}>
                {handleClickOpen ? (
                    <Tooltip title={t('tooltip.question_menu')}>
                        <IconButton onClick={handleClickOpen} style={style}>
                            <MenuOpen />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <IconButton style={style}>
                        <OpenWithIcon />
                    </IconButton>
                )}
            </div>
            <div className={css.title}>{participant}</div>
            <div style={style}>{body}</div>
            <div className={css.state_row}>
                <Tooltip
                    title={
                        answered
                            ? t('tooltip.question_answered')
                            : t('tooltip.question_mark_answered')
                    }
                >
                    <IconButton
                        disabled={state !== 'archived'}
                        onClick={toggleAnswered}
                        className={css.answered}
                    >
                        <CheckBoxIcon
                            color={answered ? 'success' : 'disabled'}
                        />
                    </IconButton>
                </Tooltip>
            </div>
            <div>
                {!showOverlay && (
                    <CommentList
                        comments={comments}
                        state={state}
                        index={index}
                    />
                )}
            </div>
        </div>
    );
}
